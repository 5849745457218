import { deepFreeze } from "../../../utils/deepFreeze";

interface CompleteRecommendTrackData {
  promoteCaption: string;
  promoteText: string;
  trackImgSrc: string;
  trackCaption: string;
  trackTitle: string;
  link: string;
  brand: Brand;
  caution?: string;
  cardCaption?: string;
}

export type Brand = "kdc" | "nbcamp" | "hhplus" | "hhreboot";

export const COMPLETE_RECOMMEND = deepFreeze<
  Record<string, CompleteRecommendTrackData>
>({
  data: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\n데이터분석 취업/이직 준비 끝!`,
    trackImgSrc: `/assets/images/campcard/campcard_data.png`,
    trackCaption: "데이터 역량 키우고, 원하는 직무 취업까지",
    trackTitle: "국비지원 데이터분석 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/data",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },
  frontend: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\n프론트엔드 개발 취업/이직 준비 끝!`,
    trackImgSrc: `/assets/images/campcard/campcard_front.png`,
    trackCaption: "차별화된 커리큘럼으로 프론트엔드 개발자 취업까지",
    trackTitle: "국비지원 프론트엔드 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/frontend",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },
  backend: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\n백엔드 웹 개발 취업/이직 준비 끝!`,
    trackImgSrc: "/assets/images/campcard/campcard_back.png",
    trackCaption: "실무와 유사한 코드로, 프로젝트 협업 역량까지",
    trackTitle: "국비지원 백엔드 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/backend",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },
  game: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\n게임 업계 취업/이직 준비 끝!`,
    trackImgSrc: "/assets/images/campcard/campcard_game.png",
    trackCaption: "기초부터 실전 프로젝트, 나만의 포트폴리오까지",
    trackTitle: "국비지원 게임 개발 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/game",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },
  app: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\n앱 개발 취업/창업 준비 끝!`,
    trackImgSrc: "/assets/images/campcard/campcard_android.png",
    trackCaption: "나만의 앱을 스토어에 출시 및 개선하는 경험까지",
    trackTitle: "국비지원 앱 개발 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/android",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },
  hub: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\nIT 업계 취업/창업 준비 끝!`,
    trackImgSrc: "/assets/images/campcard/campcard_hub.png",
    trackCaption: "코딩교육 1위 스파르타의 비전공자 IT 취업 부트캠프",
    trackTitle: "전액 국비지원 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },
  uxui: {
    promoteCaption: "혹시 지금 취업/이직 준비 중이라면?",
    promoteText: `지금부터 5개월 뒤면\nIT 업계 UXUI 취업/이직 준비 끝!`,
    trackImgSrc: "/assets/images/campcard/campcard_uxui.png",
    trackCaption: "기초부터 실전 프로젝트, 나만의 포트폴리오까지",
    trackTitle: "국비지원 UX/UI 부트캠프",
    link: "https://nbcamp.spartacodingclub.kr/uxui",
    brand: "nbcamp",
    cardCaption: "전액 무료",
  },

  plus: {
    promoteCaption: "사수 없는 주니어 개발자라면?",
    promoteText: `빅테크 시니어 코치진과\n10주간 몰입 성장해요!`,
    trackImgSrc: "/assets/images/campcard/campcard_plus.png",
    trackCaption: "현직 웹개발자 물경력 탈출 코스",
    trackTitle: "항해 플러스",
    link: "https://hhplus-hub.oopy.io/",
    brand: "hhplus",
  },
  plus_ai: {
    promoteCaption: "AI를 찐하게 배워보고 싶다면?",
    promoteText: `업계 탑티어 AI 전문가와\n8주간 몰입 성장해요!`,
    trackImgSrc: "/assets/images/campcard/campcard_plus.png",
    trackCaption: "현직 개발자 몰입 과정",
    trackTitle: "항해 플러스",
    link: "https://hhplus-ai.oopy.io/",
    brand: "hhplus",
  },
  plus_fe: {
    promoteCaption: "사수 없는 프론트엔드 주니어라면?",
    promoteText: `빅테크 시니어 코치진과\n10주간 몰입 성장해요!`,
    trackImgSrc: "/assets/images/campcard/campcard_plus.png",
    trackCaption: "현직 웹개발자 물경력 탈출 코스",
    trackTitle: "항해 플러스",
    link: "https://hanghae99.spartacodingclub.kr/plus/fe",
    brand: "hhplus",
  },
  reboot: {
    promoteCaption: "개발자 취업, 실패하고 싶지 않다면?",
    promoteText: `4단계 트레이닝으로\n체계적인 취업 코칭`,
    trackImgSrc: "/assets/images/campcard/campcard_reboot.png",
    trackCaption: "개발자 재도전을 위한 압축 코스",
    trackTitle: "항해 취업 리부트 코스",
    link: "https://hanghae99.spartacodingclub.kr/reboot",
    brand: "hhreboot",
  },
  kdc: {
    promoteCaption: "무료로 강의를 더 듣고 싶다면?",
    promoteText: `내일배움카드로
    최대 2개 강의까지 무료예요!`,
    caution: `*K-디지털 크레딧이 부족할 경우, 2개 강의 수강이\n제한될 수 있습니다.`,
    trackImgSrc: "/assets/images/campcard/campcard_kdc.webp",
    trackCaption: "수료하면 10% 결제 금액 전액 환급!",
    trackTitle: "국비지원 온라인 강의",
    link: "https://spartacodingclub.kr/nb",
    brand: "kdc",
  },
});

export const COMPLETE_RECOMMEND_BY_COURSE_ID = deepFreeze<
  Record<string, CompleteRecommendTrackData>
>({
  "654c451dd6aa5411421c298e": COMPLETE_RECOMMEND.backend,
  "64b687dc6e951768d4879e70": COMPLETE_RECOMMEND.backend,
  "648fc643f9b1fac7aced4966": COMPLETE_RECOMMEND.backend,
  "64b0a23c417100590a97e152": COMPLETE_RECOMMEND.backend,
  "6551b81ab4c831ca481ae7d3": COMPLETE_RECOMMEND.backend,
  "649938ae1e7a5e8c1b9dfb37": COMPLETE_RECOMMEND.frontend,
  "64af9cbb47cba538dd738f98": COMPLETE_RECOMMEND.frontend,
  "64a653c7640351900b810a40": COMPLETE_RECOMMEND.frontend,
  "66ca89b1daa18f1e0ea95a26": COMPLETE_RECOMMEND.frontend,
  "5f0ae408765dae0006002817": COMPLETE_RECOMMEND.app,
  "63ca26845b3bac30b29bf2a3": COMPLETE_RECOMMEND.app,
  "652bea6b08647a82bb62eb54": COMPLETE_RECOMMEND.app,
  "6361d2ce4239a844b30d4163": COMPLETE_RECOMMEND.data,
  "63ca5b43dfbe98f4c8ef20bf": COMPLETE_RECOMMEND.data,
  "62973372bf6e0e4f799fc76e": COMPLETE_RECOMMEND.data,
  "62f09f2af3ce1804901b3f5c": COMPLETE_RECOMMEND.data,
  "65369d38ceb87cbda112e69a": COMPLETE_RECOMMEND.data,
  "6083eaca6305e019d3e0c3b4": COMPLETE_RECOMMEND.game,
  "62f09eebfeb2d564bacb0262": COMPLETE_RECOMMEND.game,
  "66f66ef92c7d72f5785602d5": COMPLETE_RECOMMEND.game,

  "6535d721ceb87cbda112900a": COMPLETE_RECOMMEND.uxui,
  "66eb8b80a80c5952010dfbf9": COMPLETE_RECOMMEND.uxui,
  "6604e25bb84072071779ac26": COMPLETE_RECOMMEND.plus,
  "65fd3e2e36ebf3dc285a0703": COMPLETE_RECOMMEND.reboot,

  // 신규 데이터
  // 데이터분석 과목
  "65d2b38b1076f1d4cc075361": COMPLETE_RECOMMEND.data,
  "64956fe61e7a5e8c1b9c6e50": COMPLETE_RECOMMEND.data,
  "65d2ae1b1076f1d4cc073fd0": COMPLETE_RECOMMEND.data,
  "650abff5535c9f5382d509ee": COMPLETE_RECOMMEND.data,
  "65d2ad561076f1d4cc073cb0": COMPLETE_RECOMMEND.data,
  "65baf6587e86cf32470c1784": COMPLETE_RECOMMEND.data,
  "65d2a90040ddbb5b4661f7b0": COMPLETE_RECOMMEND.data,
  "65b06c464b408d8c7a961a05": COMPLETE_RECOMMEND.data,
  "65d2a583af84d15811dc9aa2": COMPLETE_RECOMMEND.data,
  "65b370c3949316d2d7a43847": COMPLETE_RECOMMEND.data,

  // 앱 개발 과목
  "65d2af70a5c2b2029a1030ca": COMPLETE_RECOMMEND.app,
  "65bafc2e7e86cf32470c1dec": COMPLETE_RECOMMEND.app,
  "65d2acd2a645a72c5e4a08ad": COMPLETE_RECOMMEND.app,
  "65b715cca5b5a5e6a309fe6a": COMPLETE_RECOMMEND.app,

  "65d2af11a5c2b2029a102ecb": COMPLETE_RECOMMEND.game,
  "65abb1f33cd250acb856433a": COMPLETE_RECOMMEND.game,

  // 항해 취리코
  "65d2b315a5c2b2029a103d56": COMPLETE_RECOMMEND.reboot,
  "6467063d187f226a50090d43": COMPLETE_RECOMMEND.reboot,

  // 항해 플러스 - AI 코스
  "65d2b1e4a645a72c5e4a1f20": COMPLETE_RECOMMEND.plus_ai,
  "65110da220492d270e776895": COMPLETE_RECOMMEND.plus_ai,

  // 항해 플러스 - 백/프
  "65d2b1111076f1d4cc074c62": COMPLETE_RECOMMEND.plus,
  "65bc972922e51a142eced448": COMPLETE_RECOMMEND.plus,
  "6653f2e84a59c95dcbf75cf8": COMPLETE_RECOMMEND.plus,
  "668c96d42bbb65f282155301": COMPLETE_RECOMMEND.plus,
  "66b5bd8dafab22923cb6156a": COMPLETE_RECOMMEND.plus,

  // 항해 플러스 - 프론트엔드 코스
  "65b08e7f4b408d8c7a9625e9": COMPLETE_RECOMMEND.plus_fe,
  "65d2ae8f1076f1d4cc07414b": COMPLETE_RECOMMEND.plus_fe,

  "66a093132bbb65f2821ecaf1": COMPLETE_RECOMMEND.app,
  "66a0a88fd69fa23abaa0be98": COMPLETE_RECOMMEND.backend,
  "66b17e8f73d6338061729d63": COMPLETE_RECOMMEND.frontend,
});
